<template>
  <div class="invitation">
    <div class="top-text">
      <img src="@static/vip/wenzi.png" alt="" />
    </div>
    <div class="in-text-con">
      <span class="center-text">每成功邀请一位好友</span>
      <div class="my-con">
        <span>最高可领取加油金</span>
        <span>888</span>
      </div>
    </div>
    <div class="yaoqing-btn" @click="show = true">
      <span>立即邀请</span>
    </div>
    <div class="active-item">
      <div class="active-item-title">我的成绩</div>
      <div class="active-item-con">
        <div class="my-items">
          <div class="act-item">
            <span class="act-title">分享人数</span>
            <div class="enjoy-item">
              <span class="enjoy-ren">{{ Bot_Info.spread_num }}</span>
            </div>
          </div>
          <div class="act-item">
            <span class="act-title">获得奖励</span>
            <div class="enjoy-item">
              <span class="enjoy-money">{{ Bot_Info.total_commission }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Overlay :show="show" @click="show = false" z-index="999999">
      <div class="wrapper">
        <img src="@static/vip/jiantou.png" alt="" />
        <span>点击右上角分享按钮邀请好友</span>
      </div>
    </Overlay>
  </div>
</template>
<script>
import UserInfo from "@/api/user";
import wx from "weixin-js-sdk";
import { Overlay, Dialog } from "vant";
import { goWxConfigJsApi } from "@/utils/wxpay";
export default {
  components: {
    Overlay,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      now_obj: {},
      reco_code: "",
      Bot_Info: {},
      text_type: 1,
      show: false,
      status: "0",
    };
  },
  created() {
    // 判断代理 / 用户
    if (this.$route.query.text_type) {
      (this.text_type = 2), this.new_user();
    } else {
      this.getMoney();
    }
    if (this.isWeixin_status) {
      goWxConfigJsApi("onMenuShareAppMessage");
      this.getCode();
      setTimeout(() => {
        this.enjoy_user();
      }, 800);
    }
    this.invitecode();
  },
  methods: {
    // 排行信息
    async invitecode() {
      try {
        const res = await UserInfo.getCommission();
        this.Bot_Info = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    // 获取礼品
    async getMoney() {
      try {
        const res = await UserInfo.getInviteDetail();
        if (res.code === 200) {
          this.showInfo(res.data.status);
        }
      } catch (error) {
        console.log(error);
      }
    },
    showInfo(status) {
      switch (status) {
        case 0:
          Dialog.alert({title: '温馨提示',message: '活动结束啦,期待下次开始吧',theme: 'round-button',}).then(() => {
            this.$router.push('/');
          });
          break;
        case 2:
          Dialog.alert({title: '温馨提示',message: '活动尚未开始,请耐心等待',theme: 'round-button',}).then(() => {
            this.$router.push('/');
          });
          break;
        default:
          break;
        }
    },
    // 获取邀请码
    async getCode() {
      try {
        const res = await UserInfo.getReco_code();
        if (res.code == 200) this.reco_code = res.data.reco_code;
      } catch (error) {
        console.log(error);
      }
    },
    // 分享好友
    enjoy_user() {
      var that = this;
      if (!that.reco_code) return that.$toast("无法进行分享");
      // 需在用户可能点击分享按钮前就先调用
      wx.ready(function () {
        wx.onMenuShareAppMessage({
          title: "你的好友送你350元新手大礼包!", // 分享标题
          desc: "点击领取新手礼包,最高350元", // 分享描述
          link: `https://jjtc.58zltc.com/open_card?appid=${that.appid}&get_code=${that.reco_code}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            "https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1592473926.jpeg", // 分享图标
          success: function (res) {
            console.log(res);
          },
        });
      });
    },
    // 新人礼包
    async new_user() {
      try {
        const res = await UserInfo.openCardMoney();
        if (res.code == 200) {
          res.data.con_num = res.data.coupon.length;
          this.now_obj = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.invitation {
  width: 100%;
  height: 100vh;
  display: flex;
  background: url("https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1611882322.png")
    no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.top-text {
  width: 254px;
  height: auto;
  margin: 40px 64px 22px;
}
.top-text img {
  width: 100%;
  height: 100%;
}

.in-text-con {
  width: 100%;
  position: absolute;
  display: flex;
  top: 30%;
  align-items: center;
  flex-direction: column;
}
.yaoqing-btn {
  width: 150px;
  height: 42px;
  text-align: center;
  font-size: 24px;
  color: #f16c15;
  position: absolute;
  line-height: 42px;
  letter-spacing: 2px;
  top: 60%;
  font-weight: bold;
  border-image: linear-gradient(0deg, #ffc66d, #f9eecb) 10 10;
  background: linear-gradient(0deg, #ffbf48 0%, #ffe8a4 100%);
  box-shadow: 2.5px 4.5px 3px 0px rgba(213, 32, 13, 0.67),
    0px 7.5px 5px 0px rgba(255, 200, 92, 0.5);
  border-radius: 21px;
}
.my-con {
  width: 100%;
  height: 150px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.my-con > span:nth-child(1) {
  color: #d91104;
  font-size: 18px;
  margin-top: 24px;
}
.my-con > span:nth-child(2) {
  color: #d91104;
  font-size: 50px;
  margin-top: 15px;
  font-weight: bold;
}
.my-con > span:nth-child(2)::before {
  content: "￥";
  font-size: 25px;
  font-weight: bold;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #fff;
  font-size: 16px;
  align-items: center;
}
.wrapper span {
  margin-top: 20px;
}
.wrapper img {
  width: 230px;
  height: 340px;
  margin-top: 30px;
}
.center-text {
  width: 200px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #f16c15;
  background: linear-gradient(180deg, #ffd8b3 0%, #ffdab6 100%);
  border-radius: 12px;
}
.center-money {
  min-width: 100px;
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 3.5px 20px;
  border-radius: 35px;
  text-align: center;
  background-color: #c70039;
}
.enjoy-item {
  width: 90px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 9px;
  margin-top: 9px;
  font-size: 18px;
  color: #de311a;
  font-weight: bold;
  background: #ffffff;
}
.enjoy-ren::after {
  content: "人";
  font-size: 12px;
  margin-left: 5px;
}
.enjoy-money::after {
  content: "元";
  font-size: 12px;
  margin-left: 5px;
}

.active-item {
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  bottom: 15px;
  margin-top: 120px;
  align-items: center;
  flex-direction: column;
}
.active-item-title {
  width: 280px;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(253, 171, 7, 1) 0%,
    rgba(234, 12, 20, 1) 49%,
    rgba(253, 171, 7, 1) 100%
  );
  box-shadow: 0px 9px 18px 0px rgba(236, 31, 19, 0.26);
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 40px;
  z-index: 100;
}
.my-items {
  width: 100%;
  display: flex;
  padding: 10px 0;
}
.active-item-con {
  width: 330px;
  height: 102px;
  box-shadow: 0px 9px 18px 0px rgba(250, 160, 3, 0.26);
  z-index: 50;
  margin-top: -10px;
  display: flex;
  border-radius: 9px;
  border-image: linear-gradient(-90deg, #fff8e3, #fff8e3) 10 10;
  background: linear-gradient(-10deg, #ffc66d 0%, #fff199 100%);
  box-shadow: 2px 5px 20px 0px rgba(188, 9, 0, 0.5),
    -17px -15px 18px 0px rgba(255, 210, 122, 0.5);
  flex-direction: column;
}
.act-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 15px;
}
.act-title {
  color: #de311a;
  font-size: 15px;
  font-weight: 500;
}
</style>
